import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Card = ({ gridItem }) => (
  <div className="card">
    <div className="card-image">
      <PreviewCompatibleImage imageInfo={gridItem} styles={{ height: "300px", borderRadius: "5px 5px 0 0"}} />
    </div>
    <div className="card-content">
      <h3 className={`has-text-centered ${!gridItem.text ? 'mb-0' : ''}`}>{gridItem.heading}</h3>
      <div className="content">
        {gridItem.text}
      </div>
    </div>
    {!!gridItem?.footer ? (
      <footer class="card-footer">
        <p class="card-footer-item">
          <span className="has-text-weight-semibold keep-whitespace">{gridItem.footer}</span>
        </p>
      </footer>
    ) : null }
  </div>
)

Card.propTypes = {
  gridItem: PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      link: PropTypes.string,
    }),
}

export default Card
