import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Content, { HTMLContent } from '../components/Content'
import Features from '../components/Features'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import ProjectMap from '../components/ProjectMap'
import TitleImage from '../components/TitleImage'

export const FeaturesPageTemplate = ({
  image,
  title,
  heading,
  content,
  contentComponent,
  main,
  location
}) => {
  const PageContent = contentComponent || Content

  return(
  <React.Fragment>
    {!!image ? (
      <TitleImage title={title} image={image.childImageSharp.fluid} />
    ) : null }
    <section className="section section--gradient">
      <div className="container">
        <Breadcrumbs pathname={location?.pathname}/>
        <div className="section">
          <div className="content">
            <div className="columns">
              <div className={`column is-10 is-offset-1`}>
                {!!heading ? (<PageTitle title={heading}/>) : null }
                <PageContent className={`content`} content={content} />
                {title === "Our Projects" ? (
                  <div className="box project-map">
                    <ProjectMap />
                  </div>
                ): null }
                <Features gridItems={main.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
  )
}

FeaturesPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  main: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const FeaturesPage = ({ data, location }) => {
  const { markdownRemark: pageData } = data

  return (
    <Layout>
      <FeaturesPageTemplate
        image={pageData.frontmatter.image}
        title={pageData.frontmatter.title}
        heading={pageData.frontmatter.heading}
        content={pageData.html}
        contentComponent={HTMLContent}
        main={pageData.frontmatter.main}
        location={location}
      />
    </Layout>
  )
}

FeaturesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FeaturesPage

export const featuresPageQuery = graphql`
  query FeaturesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        main {
          blurbs {
            heading
            image {
              childImageSharp {
                fluid(maxWidth: 720, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            link
            footer
          }
        }
      }
    }
  }
`
