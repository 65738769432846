import React from 'react'
import PropTypes from 'prop-types'
import Card from './Card';

// gatsby seems to strip off the first segment of the link when there is a folder with that name
// so we must add it in
function transformLinksIfRequired(link) {
  const projectLinksToTransform = ["education", "health", "elderly", "animals-and-environment", "relief"];
  const getInvolvedLinksToTransform = ["donate", "volunteer", "events"];
  if (projectLinksToTransform.includes(link)) {
    return `/projects/${link}`;
  } else if (getInvolvedLinksToTransform.includes(link)) {
    return `/get-involved/${link}`;
  }
  return link;
}

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline mt-2 is-variable is-8">
    {gridItems.map(item => (
      <div key={item.heading} className="column is-half mb-3 feature-tile">
        {item.link ? (
          <a href={transformLinksIfRequired(item.link)}>
            <Card gridItem={item}></Card>
          </a>
          ) : (<Card gridItem={item}></Card>)
        }
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

export default FeatureGrid
